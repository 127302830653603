import React from "react";
import { motion } from "framer-motion";
import { FaBox, FaClipboard, FaEnvelope, FaPhone, FaHandshake } from "react-icons/fa";
import "../Howtowork/Timeline.css";

const steps = [
  { id: 1, title: "Select Your Container", icon: <FaBox />, description: "Choose the container you require" },
  { id: 2, title: "Complete Purchase Form", icon: <FaClipboard />, description: "Fill in your details to proceed" },
  { id: 3, title: "Confirmation Email Sent", icon: <FaEnvelope />, description: "Receive an email confirmation" },
  { id: 4, title: "Representative Reaches Out", icon: <FaPhone />, description: "Our representative contacts you" },
  { id: 5, title: "Deal Finalized Successfully", icon: <FaHandshake />, description: "Business deal is closed successfully" },
];

const Timeline = () => {
  return (
    <div className="timeline-container ">
      <h2 className="timeline-heading">How to Get a Container</h2> {/* Added heading */}
      {steps.map((step, index) => (
        <div key={step.id} className="timeline-step-container">
          <motion.div
            className="timeline-step"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.3 }}
            whileHover={{ scale: 1.05 }}
          >
            <div className="icon-circle">
              <div className="icon">{step.icon}</div>
            </div>
            <h3>{step.title}</h3>
            <p>{step.description}</p>
          </motion.div>
          {index < steps.length - 1 && <div className="connector-line" />}
        </div>
      ))}
    </div>
  );
};

export default Timeline;
