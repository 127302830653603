import React, { useState } from "react";
import Slider from "react-slick";
// import './App.css'; // External CSS for styling
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../Howtowork/Howtowork.css";
import dummyimg from "../../Pages/images/transport.jpg";
import dummyimgtwo from "../../Pages/images/transtwo.jpg";
import { useNavigate } from "react-router-dom";
// import AssetDisplay from './Assets';
import homepagevideo from '../../Components/images/Brooksedited.mp4'

import Assetsdefault from "../Howtowork/Assets.jsx";

import scne from "../images/scne.mp4";

const HowtoCarousel = () => {
  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };
  const isMobile = window.innerWidth < 768;

  const assetData = {
    1: {
      heading:
        "We offer a variety of insured assets for lease and buyback, including: Dry freight containers, tank containers, and more.",
      title: "40ft Dry Container",
      price: "$3,000 USD",
      details: {
        weight: "5,000kg",
        lifespan: "30 years",
        maxPayload: "29,000kg",
        material: "Corrosal Steel",
        usedBy: "Logistics",
      },
      image: dummyimg,
    },
    2: {
      title: "20ft Dry Container",
      price: "$2,000 USD",
      details: {
        weight: "3,000kg",
        lifespan: "25 years",
        maxPayload: "20,000kg",
        material: "Steel",
        usedBy: "Shipping",
      },
      image: dummyimgtwo,
    },

    3: {
      title: "20ft Dry Container",
      price: "$2,000 USD",
      details: {
        weight: "3,000kg",
        lifespan: "25 years",
        maxPayload: "20,000kg",
        material: "Steel",
        usedBy: "Shipping",
      },
      image: dummyimgtwo,
    },

    4: {
      title: "20ft Dry Container",
      price: "$2,000 USD",
      details: {
        weight: "3,000kg",
        lifespan: "25 years",
        maxPayload: "20,000kg",
        material: "Steel",
        usedBy: "Shipping",
      },
      image: dummyimgtwo,
    },
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const handleVideoClick = () => {
    window.open("https://player.vimeo.com/video/1029593628?h=3c607d7b66", "_blank");
  };
  const Navi = useNavigate();
  return (
    <div className="container ">
      <div className="row">
        <div className="col-md-5">
          <Assetsdefault />
        </div>
        <div className="col-md-7 mb-2 justify-content-center d-flex align-items-center">
<div ></div>
<div className="video-background-container-r" onClick={handleVideoClick}>
  
      <video
        src={homepagevideo}
        className="video-background-r"
        loop
        muted
        autoPlay
        playsInline // Ensures autoplay on mobile devices (iOS/Safari)
      />
     
    </div>

          {/* <Assetsdefault /> */}
        </div>
      </div>

   
    </div>
  );
};

export default HowtoCarousel;
